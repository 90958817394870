import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/analytics'
import 'firebase/functions'
import 'firebase/performance'
import axios from 'axios'
import LogRocket from 'logrocket'

const firebaseConfig = {
  apiKey: "AIzaSyBlXqEKgZeHJ0sDc9mQuir783VlpNcrKFw",
  authDomain: "investaflow.com",
  databaseURL: "https://financial-app-77c2c.firebaseio.com",
  projectId: "financial-app-77c2c",
  storageBucket: "financial-app-77c2c.appspot.com",
  messagingSenderId: "960028964906",
  appId: "1:960028964906:web:01ef3fdcf96ba3e0206b53",
  measurementId: "G-51LR0MBF8V"
}

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
} else {
  firebase.app()
}

firebase.analytics()
firebase.performance()

const storage = firebase.storage()

// prod
const db = firebase.firestore()
const functions = firebase.functions()
const firebaseBaseURL = 'https://us-central1-financial-app-77c2c.cloudfunctions.net/'

//local
// firebase.functions().useEmulator("localhost", 5001)
// const firebaseBaseURL = 'http://localhost:5001/financial-app-77c2c/us-central1/'

//#region Auth

export const createUserWithEmailAndPassword = (email, password) => {
  return firebase.auth().createUserWithEmailAndPassword(email, password)
}

export const signInWithEmailAndPassword = (email, password) => {
  return firebase.auth().signInWithEmailAndPassword(email, password)
}

export const signInWithGoogle = () => {
  const provider = new firebase.auth.GoogleAuthProvider()
  return firebase.auth().signInWithPopup(provider)
}

export const signOut = () => {
  return firebase.auth().signOut()
}

export const onAuthStateChanged = (callback) => {
  firebase.auth().onAuthStateChanged((user => {
    callback(user)
  }))
}

export const sendPasswordResetEmail = (email) => {
  return firebase.auth().sendPasswordResetEmail(email)
}

//#endregion

//#region User

export const updateUser = (name, email, user) => {
  const currentUserDoc = db.collection("users").doc(user.uid)

  return currentUserDoc.get().then((document) => {
    const data = {
      userId: user.uid,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    }

    currentUserDoc.set(data, { merge: true })

    firebase.analytics().setUserId(user.uid)

    if (name != null) {
      //database
      currentUserDoc.update({ name: name })

      //auth
      user.updateProfile({ displayName: name })
    }

    if (email != null) {
      //database
      currentUserDoc.collection("private").doc("all").set({ email: email }, { merge: true })

      //auth
      user.updateEmail(email)
    }

    if (!document.exists || document.data().createdAt === null || document.data().createdAt === undefined) {
      currentUserDoc.update({ createdAt: firebase.firestore.FieldValue.serverTimestamp() })
    }

    //log rocket
    LogRocket.identify(user.uid, {
      name: name,
      email: email
    })
  })

}

export const getCurrentUser = () => {
  const currentUser = getAuthUser()
  if (currentUser == null) { return }

  return db.collection("users").doc(currentUser.uid).get().then(doc => {
    return ({ ...doc.data(), id: doc.id })
  })
}

export const subscribeToCurrentUser = (callback) => {
  const currentUser = getAuthUser()
  if (currentUser == null) { return }

  db.collection("users").doc(currentUser.uid).onSnapshot(doc => {
    callback({ ...doc.data(), id: doc.id })
  })
}

export const setUserOnboarded = (isOnboarded) => {
  const currentUser = getAuthUser()
  if (currentUser == null) { return }

  const currentUserDoc = db.collection("users").doc(currentUser.uid)

  return currentUserDoc.update({ isOnboarded })
}

export const updateProfileImage = (url) => {
  const currentUser = getAuthUser()
  if (currentUser == null || url == null) { return }

  const thumbnailRef = storage.ref().child(`profileImages/${currentUser.uid}/original.jpg`)

  const instance = axios.create()
  return instance.get(url, { responseType: 'arraybuffer' }).then((response) => {
    let buffer = Buffer.from(response.data, 'binary')
    return thumbnailRef.put(buffer, { contentType: 'image/jpeg' })
    //update users photo url here?
  }).catch((error) => {
    //catch to not interupt flow
    console.log('updateProfileImageError')
    console.log(error)
  })
}

export const getProfileImageUrl = () => {
  const currentUser = getAuthUser()
  if (currentUser == null) { return }

  const thumbnailRef = storage.ref().child(`profileImages/${currentUser.uid}/original_200x200.jpg`)

  return thumbnailRef.getDownloadURL().catch(() => {
    const smallThumbnailRef = storage.ref().child(`profileImages/${currentUser.uid}/original_100x100.jpg`)
    return smallThumbnailRef.getDownloadURL().catch(() => { })
  })
}

export const deleteProfileImage = () => {
  const currentUser = getAuthUser()
  if (currentUser == null) { return }

  const thumbnailRef = storage.ref().child(`rentalImages/${currentUser.uid}/original_100x100.jpg`)
  const mediumRef = storage.ref().child(`rentalImages/${currentUser.uid}/original_200x200.jpg`)
  const largeRef = storage.ref().child(`rentalImages/${currentUser.uid}/original.jpg`)

  return thumbnailRef.delete()
    .then(() => { return mediumRef.delete() })
    .then(() => { return largeRef.delete() })
}

export const validateEmail = (email) => {
  const validateEmail = functions.httpsCallable('validateEmail')

  return validateEmail({ email }).then((response) => {
    return response.data.valid

  }).catch(() => {
    return false
  })
}

export const subscribeToSubscription = (callback) => {
  const currentUser = getAuthUser()
  if (currentUser == null) { return }

  db.collection('users').doc(currentUser.uid).collection('subscriptions').where('status', '==', 'active').limit(1).get().then(subSnapshot => {
    if (subSnapshot.docs.length > 0) {
      const doc = subSnapshot.docs[0]
      const productName = doc.data().product.id === 'prod_IKFstkrPX0JtsR' ? 'basic' : 'premium'
      callback({ ...doc.data(), id: doc.id, productName })
    } else {
      callback({})
    }
  })
}

//#endregion

//#region Events

export const logEvent = (eventName, params) => {
  firebase.analytics().logEvent(eventName, params)
}

//#endregion

//#region Plaid

export const createLinkToken = (institutionId) => {
  const currentUser = getAuthUser()
  if (currentUser == null) { return }

  const instance = axios.create({ baseURL: firebaseBaseURL })
  return instance.get('createPlaidLinkToken', { params: { userId: currentUser.uid, institutionId } }).then((response) => {
    return response.data.link_token
  }).catch(() => {
    return null
  })
}

export const exchangePublicToken = (publicToken, institution, accounts) => {
  const currentUser = getAuthUser()
  if (currentUser == null) { return }

  const exchangePlaidPublicToken = functions.httpsCallable('exchangePlaidPublicToken')

  return exchangePlaidPublicToken({ publicToken, institution, accounts, userId: currentUser.uid }).then((response) => {
    return response.data.institutionId
  }).catch((error) => {
    console.log('exchangePlaidPublicTokenFail')
    console.log(error)
    return ''
  })
}

export const relinkedInstitution = () => {
  const currentUser = getAuthUser()
  if (currentUser == null) { return }

  const relinkedPlaidInstitution = functions.httpsCallable('relinkedPlaidInstitution')

  return relinkedPlaidInstitution({ userId: currentUser.uid }).then(() => {
    return
  }).catch((error) => {
    console.log('relinkedPlaidInstitutionFail')
    console.log(error)
    return
  })
}

export const subscribeToAccounts = (institutionId, callback) => {
  const currentUser = getAuthUser()
  if (currentUser == null) { return }

  db.collection("users").doc(currentUser.uid).collection("institutions").doc(institutionId).collection("accounts").onSnapshot(snapshot => {
    callback(snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } }))
  })
}

export const subscribeToInstitutions = (callback) => {
  const currentUser = getAuthUser()
  if (currentUser == null) { return }

  db.collection("users").doc(currentUser.uid).collection("institutions").onSnapshot(snapshot => {
    callback(snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } }))
  })
}

export const deleteInstitution = (id) => {
  const currentUser = getAuthUser()
  if (currentUser == null) { return }

  return db.collection("users").doc(currentUser.uid).collection("institutions").doc(id).delete()
}

export const getInstitutionLogoUrl = (insitutionId) => {
  const logoRef = storage.ref().child(`institutionImages/${insitutionId}/logo.jpg`)
  return logoRef.getDownloadURL().catch(() => { })
}

//#endregion

//#region Stripe

export const redirectToInvestorCheckout = async (monthly, plan) => {
  const currentUser = getAuthUser()
  if (currentUser == null) { return }

  const { loadStripe } = await import('@stripe/stripe-js')
  const stripePromise = loadStripe('pk_live_51He9YUAUdNKBkAdfipLODqbpgNZptmuvEjKWZKGk7HxFPVz5N2ztQJIGkCX3PMKL83TPDiJ1OI6C6rtUuP98RXj200H84jPIcM')
  const stripe = await stripePromise
  const price = plan === 'basic' ?
    (monthly ? 'price_1HjbNBAUdNKBkAdfjkpVBpnw' : 'price_1HjbNBAUdNKBkAdfs6TuKbF3') :
    (monthly ? 'price_1IYcntAUdNKBkAdfYVdNJOc5' : 'price_1IYcntAUdNKBkAdfIXHjDnWo')

  const docRef = await db.collection('users').doc(currentUser.uid).collection('checkout_sessions')
    .add({
      price,
      success_url: window.location.origin + `/dashboard?purchaseSuccess=true&plan=${plan}`,
      cancel_url: window.location.origin + '/purchase',
    })

  // Wait for the CheckoutSession to get attached by the extension
  docRef.onSnapshot((snap) => {
    const { sessionId } = snap.data()
    if (sessionId) {
      stripe.redirectToCheckout({ sessionId })
    }
  })

}

export const getStripeLink = async () => {
  const functionRef = functions.httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink')
  const { data } = await functionRef({ returnUrl: window.location.origin })
  return data.url
}

//#endregion

//#region Contact

export const submitContactForm = (name, email, message) => {
  const instance = axios.create({ baseURL: firebaseBaseURL })

  return instance.get('submitContactForm', { params: { name, email, message } }).then(() => {
    return true
  }).catch(() => {
    return false
  })

}

//#endregion

//#region Accounts

export const updateAccountImage = (accountId, url) => {
  if (url == null) { return }
  const thumbnailRef = storage.ref().child(`accountImages/${accountId}/original.jpg`)

  const instance = axios.create()
  return instance.get(url, { responseType: 'arraybuffer' }).then((response) => {
    let buffer = Buffer.from(response.data, 'binary')
    return thumbnailRef.put(buffer, { contentType: 'image/jpeg' })
  })
}

export const getAccountImageUrl = (accountId) => {
  const thumbnailRef = storage.ref().child(`accountImages/${accountId}/original_200x200.jpg`)
  return thumbnailRef.getDownloadURL().catch(() => {
    const smallThumbnailRef = storage.ref().child(`accountImages/${accountId}/original_100x100.jpg`)
    return smallThumbnailRef.getDownloadURL().catch(() => { })
  })
}

export const getAccountImageLargeUrl = (accountId) => {
  const largeRef = storage.ref().child(`accountImages/${accountId}/original.jpg`)
  return largeRef.getDownloadURL().catch(() => { })
}

export const deleteAccountImage = (accountId) => {
  const thumbnailRef = storage.ref().child(`accountImages/${accountId}/original_100x100.jpg`)
  const mediumRef = storage.ref().child(`accountImages/${accountId}/original_200x200.jpg`)
  const largeRef = storage.ref().child(`accountImages/${accountId}/original.jpg`)

  return thumbnailRef.delete()
    .then(() => { return mediumRef.delete() })
    .then(() => { return largeRef.delete() })
}

//#endregion

//#region House

export const addConnectedHouseAsset = (address) => {
  const userId = firebase.auth().currentUser.uid
  const addConnectedHouse = functions.httpsCallable('addConnectedHouse')

  return addConnectedHouse({ address, userId }).then((response) => {
    return response.data
  }).catch((error) => {
    console.log(error)
    return {}
  })
}

export const updateConnectedHouseAsset = (assetId, address) => {
  const updateConnectedHouse = functions.httpsCallable('updateConnectedHouse')

  return updateConnectedHouse({ assetId, address }).then((response) => {
    return response.data
  }).catch((error) => {
    console.log(error)
    return {}
  })
}

//#endregion

//#region Assets

export const addAsset = (assetType, name, value, institutionId, accountId) => {
  const currentUser = getAuthUser()
  if (currentUser == null) { return }

  const timestamp = firebase.firestore.FieldValue.serverTimestamp()
  return db.collection("assets").add({
    assetType,
    name,
    value,
    institutionId,
    accountId,
    userId: currentUser.uid,
    createdAt: timestamp,
    updatedAt: timestamp,
  })
}

export const editAsset = (id, name, value, institutionId, accountId) => {
  const timestamp = firebase.firestore.FieldValue.serverTimestamp()
  return db.collection("assets").doc(id).update({ name, value, institutionId, accountId, updatedAt: timestamp })
}

export const addHouseAsset = (assetType, name, value, street, city, state) => {
  const currentUser = getAuthUser()
  if (currentUser == null) { return }

  const timestamp = firebase.firestore.FieldValue.serverTimestamp()
  return db.collection("assets").add({ assetType, name, value, street, city, state, userId: currentUser.uid, createdAt: timestamp, updatedAt: timestamp })
}

export const editHouseAsset = (id, name, value, street, city, state, beds, baths, squareFeet, connected) => {
  const timestamp = firebase.firestore.FieldValue.serverTimestamp()
  return db.collection("assets").doc(id).update({ name, value, street, city, state, beds, baths, squareFeet, connected, updatedAt: timestamp })
}

export const disconnectHouseAsset = (id) => {
  const timestamp = firebase.firestore.FieldValue.serverTimestamp()
  return db.collection("assets").doc(id).update({ connected: null, updatedAt: timestamp })
}

export const addVehicleAsset = (assetType, name, value, make, model, year) => {
  const currentUser = getAuthUser()
  if (currentUser == null) { return }

  const timestamp = firebase.firestore.FieldValue.serverTimestamp()
  return db.collection("assets").add({ assetType, name, value, make, model, year, userId: currentUser.uid, createdAt: timestamp, updatedAt: timestamp })
}

export const editVehicleAsset = (id, name, value, make, model, year) => {
  const timestamp = firebase.firestore.FieldValue.serverTimestamp()
  return db.collection("assets").doc(id).update({ name, value, make, model, year, updatedAt: timestamp })
}

export const deleteAsset = (id) => {
  return db.collection("assets").doc(id).delete().then(() => {
    db.collection("debts").where("assetId", "==", id).onSnapshot(snapshot => {
      snapshot.docs.forEach(doc => { doc.ref.update({ assetId: firebase.firestore.FieldValue.delete() }) })
    })
    db.collection("incomes").where("assetId", "==", id).onSnapshot(snapshot => {
      snapshot.docs.forEach(doc => { doc.ref.delete() })
    })
    db.collection("expenses").where("assetId", "==", id).onSnapshot(snapshot => {
      snapshot.docs.forEach(doc => { doc.ref.delete() })
    })
  })
}

export const subscribeToAssets = (callback) => {
  const currentUser = getAuthUser()
  if (currentUser == null) { return }

  db.collection("assets").where("userId", "==", currentUser.uid).onSnapshot(snapshot => {
    callback(snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } }))
  })
}

export const subscribeToAsset = (id, callback) => {
  db.collection("assets").doc(id).onSnapshot(doc => {
    if (doc.data()) {
      callback({ ...doc.data(), id: doc.id })
    } else {
      callback({})
    }
  })
}

export const linkIncomeToAsset = (incomeId, assetId) => {
  return db.collection("incomes").doc(incomeId).update({ assetId })
}

export const linkDebtToAsset = (debtId, assetId) => {
  return db.collection("debts").doc(debtId).update({ assetId })
}

export const linkExpenseToAsset = (expenseId, assetId) => {
  return db.collection("expenses").doc(expenseId).update({ assetId })
}

export const delinkIncomeToAsset = (incomeId) => {
  return db.collection("incomes").doc(incomeId).update({ assetId: null })
}

export const delinkDebtToAsset = (debtId) => {
  return db.collection("debts").doc(debtId).update({ assetId: null })
}

export const delinkExpenseToAsset = (expenseId) => {
  return db.collection("expenses").doc(expenseId).update({ assetId: null })
}

//#endregion

//#region Income

export const addIncome = (name, value, assetId) => {
  const currentUser = getAuthUser()
  if (currentUser == null) { return }

  const timestamp = firebase.firestore.FieldValue.serverTimestamp()
  return db.collection("incomes").add({ name, value, assetId, userId: currentUser.uid, createdAt: timestamp, updatedAt: timestamp })
}

export const editIncome = (id, name, value) => {
  const timestamp = firebase.firestore.FieldValue.serverTimestamp()
  return db.collection("incomes").doc(id).update({ name, value, updatedAt: timestamp })
}

export const deleteIncome = (id) => {
  return db.collection("incomes").doc(id).delete()
}

export const subscribeToIncomes = (callback) => {
  const currentUser = getAuthUser()
  if (currentUser == null) { return }

  db.collection("incomes").where("userId", "==", currentUser.uid).onSnapshot(snapshot => {
    callback(snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } }))
  })
}

export const subscribeToIncome = (id, callback) => {
  db.collection("incomes").doc(id).onSnapshot(doc => {
    if (doc.data()) {
      callback({ ...doc.data(), id: doc.id })
    } else {
      callback({})
    }
  })
}

//#endregion

//#region Debt

export const addDebt = (debtType, name, value, interest, minimumPayment, assetId, institutionId, accountId) => {
  const currentUser = getAuthUser()
  if (currentUser == null) { return }

  const timestamp = firebase.firestore.FieldValue.serverTimestamp()
  return db.collection("debts").add({
    debtType,
    name,
    value,
    interest,
    minimumPayment,
    additionalPayment: 0,
    assetId,
    institutionId,
    accountId,
    userId: currentUser.uid,
    createdAt: timestamp,
    updatedAt: timestamp,
  })
}

export const editDebt = (id, name, value, interest, minimumPayment, institutionId, accountId) => {
  const timestamp = firebase.firestore.FieldValue.serverTimestamp()
  return db.collection("debts").doc(id).update({ name, value, interest, minimumPayment, institutionId, accountId, updatedAt: timestamp })
}

export const addMortgageDebt = (debtType, name, value, interest, minimumPayment, escrow, assetId) => {
  const currentUser = getAuthUser()
  if (currentUser == null) { return }

  const timestamp = firebase.firestore.FieldValue.serverTimestamp()
  return db.collection("debts").add({
    debtType,
    name,
    value,
    interest,
    minimumPayment,
    escrow,
    additionalPayment: 0,
    assetId,
    userId: currentUser.uid,
    createdAt: timestamp,
    updatedAt: timestamp,
  })
}

export const editMortgageDebt = (id, name, value, interest, minimumPayment, escrow, institutionId, accountId) => {
  const timestamp = firebase.firestore.FieldValue.serverTimestamp()
  return db.collection("debts").doc(id).update({ name, value, interest, minimumPayment, escrow, institutionId, accountId, updatedAt: timestamp })
}

export const deleteDebt = (id) => {
  return db.collection("debts").doc(id).delete()
}

export const subscribeToDebts = (callback) => {
  const currentUser = getAuthUser()
  if (currentUser == null) { return }

  db.collection("debts").where("userId", "==", currentUser.uid).onSnapshot(snapshot => {
    callback(snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } }))
  })
}

export const subscribeToDebt = (id, callback) => {
  db.collection("debts").doc(id).onSnapshot(doc => {
    if (doc.data()) {
      callback({ ...doc.data(), id: doc.id })
    } else {
      callback({})
    }
  })
}

export const updateAdditionalPayment = (id, additionalPayment) => {
  return db.collection("debts").doc(id).update({ additionalPayment }).then(() => {
    db.collection("goals").where("accountId", "==", id).limit(1).get().then(snapshot => {
      snapshot.docs.forEach(doc => { doc.ref.update({ monthlyContribution: additionalPayment }) })
    })
  })
}

//#endregion

//#region Expense

export const addExpense = (name, value, assetId) => {
  const currentUser = getAuthUser()
  if (currentUser == null) { return }
  const timestamp = firebase.firestore.FieldValue.serverTimestamp()
  return db.collection("expenses").add({ name, value, assetId, userId: currentUser.uid, createdAt: timestamp, updatedAt: timestamp })
}

export const editExpense = (id, name, value) => {
  const timestamp = firebase.firestore.FieldValue.serverTimestamp()
  return db.collection("expenses").doc(id).update({ name, value, updatedAt: timestamp })
}

export const deleteExpense = (id) => {
  return db.collection("expenses").doc(id).delete()
}

export const subscribeToExpenses = (callback) => {
  const currentUser = getAuthUser()
  if (currentUser == null) { return }

  db.collection("expenses").where("userId", "==", currentUser.uid).onSnapshot(snapshot => {
    callback(snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } }))
  })
}

export const subscribeToExpense = (id, callback) => {
  db.collection("expenses").doc(id).onSnapshot(doc => {
    if (doc.data()) {
      callback({ ...doc.data(), id: doc.id })
    } else {
      callback({})
    }
  })
}

//#endregion

//#region Goal

export const addGoal = (type, accountId, goalAmount, monthlyContribution) => {
  const currentUser = getAuthUser()
  if (currentUser == null) { return }

  //todo delete existing goals first?
  if (type === 'saveDownPayment' || type === 'saveSafetyNet') {
    return db.collection("goals").add({ type, accountId, goalAmount, monthlyContribution, userId: currentUser.uid })
  } else if (type === 'payoffDebt') {
    return db.collection("goals").add({ type, accountId, goalAmount: null, monthlyContribution, userId: currentUser.uid }).then(() => {
      db.collection("debts").doc(accountId).update({ additionalPayment: monthlyContribution })
    })
  }
}

export const editGoal = (id, type, accountId, goalAmount, monthlyContribution) => {
  return db.collection("goals").doc(id).update({ type, accountId, goalAmount, monthlyContribution })
}

export const deleteGoal = (id) => {
  return db.collection("goals").doc(id).delete()
}

export const subscribeToGoal = (callback) => {
  const currentUser = getAuthUser()
  if (currentUser == null) { return }

  db.collection("goals").where("userId", "==", currentUser.uid).limit(1).onSnapshot(snapshot => {
    if (snapshot.docs.length > 0) {
      callback({ ...snapshot.docs[0].data(), id: snapshot.docs[0].id })
    } else {
      callback({})
    }
  })
}

//#endregion

//#region History

export const subscribeToAssetHistory = (assetId, callback) => {
  db.collection("assets").doc(assetId).collection('history').onSnapshot(snapshot => {
    callback(snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } }))
  })
}

export const subscribeToDebtHistory = (debtId, callback) => {
  db.collection("debts").doc(debtId).collection('history').onSnapshot(snapshot => {
    callback(snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } }))
  })
}

export const subscribeToIncomeHistory = (incomeId, callback) => {
  db.collection("incomes").doc(incomeId).collection('history').onSnapshot(snapshot => {
    callback(snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } }))
  })
}

export const subscribeToExpenseHistory = (expenseId, callback) => {
  db.collection("expenses").doc(expenseId).collection('history').onSnapshot(snapshot => {
    callback(snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } }))
  })
}

export const deleteHistory = (accountType, accountId, historyId) => {
  return db.collection(accountType).doc(accountId).collection('history').doc(historyId).delete()
}

//#endregion

//#region Rentals

export const addConnectedRental = (address, addressObject) => {
  const userId = firebase.auth().currentUser.uid
  const addRentalProperty = functions.httpsCallable('addRentalProperty')

  return addRentalProperty({ address, addressObject, userId }).then((response) => {
    return response.data
  })
}

export const addRental = (name, street, city, state, value, rentAmount) => {
  const currentUser = getAuthUser()
  if (currentUser == null) { return }

  const timestamp = firebase.firestore.FieldValue.serverTimestamp()
  return db.collection("rentals").add({
    name, street, city, state, value, rentAmount,
    userId: currentUser.uid, createdAt: timestamp, updatedAt: timestamp,
    purchasePrice: value, closingCost: value * 0.03, cashPurchase: false, downPaymentPercent: 20, interest: 4, loanDuration: 30, vacancy: 10, propertyManagement: 10, repairs: 10, capEx: 5,
  })
}

export const editRental = (id, name, propertyType, street, city, state, beds, baths, squareFeet, value, description) => {
  const timestamp = firebase.firestore.FieldValue.serverTimestamp()
  return db.collection("rentals").doc(id).update({ name, propertyType, street, city, state, beds, baths, squareFeet, value, description, updatedAt: timestamp })
}

export const editRentalPurchaseDetails = (id, purchasePrice, renoCost, closingCost, cashPurchase, downPaymentPercent, interest, loanDuration, points, otherFees, rentAmount, otherIncome, vacancy, electric, water, sewer, trash, hoa, insurance, otherExpense, propertyTax, propertyManagement, repairs, capEx) => {
  const timestamp = firebase.firestore.FieldValue.serverTimestamp()
  return db.collection("rentals").doc(id).update({ purchasePrice, renoCost, closingCost, cashPurchase, downPaymentPercent, interest, loanDuration, points, otherFees, rentAmount, otherIncome, vacancy, electric, water, sewer, trash, hoa, insurance, otherExpense, propertyTax, propertyManagement, repairs, capEx, updatedAt: timestamp })
}

export const deleteRental = (id) => {
  return db.collection("rentals").doc(id).delete()
}

export const subscribeToRentals = (callback) => {
  const currentUser = getAuthUser()
  if (currentUser == null) { return }

  db.collection("rentals").where("userId", "==", currentUser.uid).onSnapshot(snapshot => {
    callback(snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } }))
  })
}

export const subscribeToRental = (id, callback) => {
  db.collection("rentals").doc(id).onSnapshot(doc => {
    if (doc.data()) {
      callback({ ...doc.data(), id: doc.id })
    } else {
      callback({})
    }
  })
}

export const updateRentalImage = (rentalId, url) => {
  if (url == null) { return }
  const thumbnailRef = storage.ref().child(`rentalImages/${rentalId}/original.jpg`)

  const instance = axios.create()
  return instance.get(url, { responseType: 'arraybuffer' }).then((response) => {
    let buffer = Buffer.from(response.data, 'binary')
    return thumbnailRef.put(buffer, { contentType: 'image/jpeg' })
  })
}

export const getRentalImageUrl = (rentalId) => {
  const thumbnailRef = storage.ref().child(`rentalImages/${rentalId}/original_200x200.jpg`)
  return thumbnailRef.getDownloadURL().catch(() => {
    const smallThumbnailRef = storage.ref().child(`rentalImages/${rentalId}/original_100x100.jpg`)
    return smallThumbnailRef.getDownloadURL().catch(() => { })
  })
}

export const getRentalImageLargeUrl = (rentalId) => {
  const largeRef = storage.ref().child(`rentalImages/${rentalId}/original.jpg`)
  return largeRef.getDownloadURL().catch(() => { })
}

export const deleteRentalImage = (rentalId) => {
  const thumbnailRef = storage.ref().child(`rentalImages/${rentalId}/original_100x100.jpg`)
  const mediumRef = storage.ref().child(`rentalImages/${rentalId}/original_200x200.jpg`)
  const largeRef = storage.ref().child(`rentalImages/${rentalId}/original.jpg`)

  return thumbnailRef.delete()
    .then(() => { return mediumRef.delete() })
    .then(() => { return largeRef.delete() })
}

export const getRentalSettings = () => {
  const currentUser = getAuthUser()
  return db.collection("users").doc(currentUser.uid).collection("rentalSettings").doc("all").get().then(doc => {
    return doc.data() || {}
  })
}

export const subscribeToRentalSettings = (callback) => {
  const currentUser = getAuthUser()
  db.collection("users").doc(currentUser.uid).collection("rentalSettings").doc("all").onSnapshot(doc => {
    callback(doc.data() || {})
  })
}

export const editRentalSettings = (cashflow, capRate, coc) => {
  const currentUser = getAuthUser()
  const timestamp = firebase.firestore.FieldValue.serverTimestamp()
  return db.collection("users").doc(currentUser.uid).collection("rentalSettings").doc("all").set({ cashflow, capRate, coc, updatedAt: timestamp }, { merge: true })
}

//#endregion

//#region Helpers

const getAuthUser = () => {
  let currentUser = JSON.parse(localStorage.getItem('user'))

  if (currentUser == null) {
    currentUser = firebase.auth().currentUser
  }

  return currentUser || null

}

//#endregion