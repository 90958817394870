import React from 'react'
import { Route } from "react-router-dom"

import * as BS from 'react-bootstrap'

import Menu from './Menu'

const SharedRouteWrapper = ({ component: Component, coloredBackground, ...rest }) => {

  return (
    <Route {...rest} render={props => (
      <div id="outer-container">
        <Menu />
        <main id="page-wrap" className="bg-white fill-height">
          <div className={coloredBackground ? "onboarding-background" : ""}>
            <BS.Container className="page-wrapper pb-5 ">
              <Component {...props} />
            </BS.Container>
          </div>
        </main>
      </div>
    )}
    />
  )
}

export default SharedRouteWrapper
