import React, { useEffect, useState } from 'react'
import { Route, withRouter } from "react-router-dom"
import LogRocket from 'logrocket'

import * as BS from 'react-bootstrap'

import Menu from './Menu'

import { onAuthStateChanged } from '../../utils/firebaseUtils'

const InternalRouteWrapper = ({ component: Component, coloredBackground, history, ...rest }) => {
  const [user, setUser] = useState(null)

  useEffect(() => {
    onAuthStateChanged(user => {
      localStorage.setItem('user', JSON.stringify(user))
      setUser(user)

      if (!user) {
        history.replace('/')
        return
      }

      //log rocket
      LogRocket.identify(user.uid, {
        name: user.displayName,
        email: user.email
      })

    })
  }, [history])

  return (
    <Route {...rest} render={props => (
      <div id="outer-container">
        <Menu />
        <main id="page-wrap" className="bg-white fill-height">
          <div>
            <BS.Container className="page-wrapper pb-5">
              {user &&
                <Component {...props} />
              }
            </BS.Container>
          </div>
        </main>
      </div>
    )}
    />
  )
}

export default withRouter(InternalRouteWrapper)
