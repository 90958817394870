import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { push as BurgerMenu } from 'react-burger-menu'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as FA from '@fortawesome/free-solid-svg-icons'
import * as BS from 'react-bootstrap'

import logo from '../../images/investaflow-logo.svg'

import { getProfileImageUrl, subscribeToCurrentUser, subscribeToInstitutions, subscribeToAssets, onAuthStateChanged, subscribeToRentals, subscribeToSubscription } from '../../utils/firebaseUtils'

import profileImage from '../../images/defaultProfileImage.png'

class Menu extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      isLoggedIn: false,
      profileImageUrl: null,
      user: {},
      isMenuOpen: false,
      institutions: [],
      connectedAssets: [],
      rentals: [],
      subscription: {},
    }

  }

  componentDidMount() {
    onAuthStateChanged((currentUser) => {
      if (currentUser) {
        this.setState({ isLoading: false, isLoggedIn: true })

        subscribeToCurrentUser(user => {
          this.setState({ user: user })
        })

        getProfileImageUrl().then(profileImageUrl => {
          this.setState({ profileImageUrl })
        })

        subscribeToInstitutions(institutions => {
          this.setState({ institutions })
        })

        subscribeToAssets(assets => {
          this.setState({ connectedAssets: assets.filter(a => a.connected === true) })
        })

        subscribeToRentals(rentals => {
          this.setState({ rentals })
        })

        subscribeToSubscription(subscription => {
          this.setState({ subscription })
        })
      }
    })


  }

  handleOnOpen = () => {
    this.setState({ isMenuOpen: true })
  }

  handleOnClose = () => {
    this.setState({ isMenuOpen: false })
  }

  render() {
    const { user, profileImageUrl, isLoggedIn, isLoading, isMenuOpen, institutions, connectedAssets, rentals, subscription } = this.state
    const connectedCount = institutions.length + connectedAssets.length

    if (!isLoading && !isLoggedIn) {
      return (<></>)
    }

    return (
      <>
        <BurgerMenu isOpen={isMenuOpen} onOpen={this.handleOnOpen} onClose={this.handleOnClose} width="320px" pageWrapId="page-wrap" outerContainerId="outer-container" >
          <BS.Row className="d-flex flex-row align-items-center justify-content-between align-items-center">
            <BS.Card bg="dark" className="p-2 mb-4 w-100 line-height-1 mr-3">
              <Link to="/profile" onClick={this.handleOnClose}>
                <div className="d-flex align-items-center text-light">
                  <BS.Image src={profileImageUrl || profileImage} height="40" width="40" roundedCircle alt="your display portrait" className="cover mr-3" />
                  <p className="mb-0 font-weight-bold">{user.name}</p>
                </div>
              </Link>
              <hr className="my-2" />
              {subscription.productName == null &&
                <div className="p-1">
                  <p className="h6 font-weight-bold text-white disable-cursor mb-0">Free Plan</p>
                  {connectedCount > 0 &&
                    <p className="small text-soft disable-cursor mb-2">{connectedCount} of 2 accounts connected</p>
                  }
                  {rentals.length > 0 &&
                    <p className="small text-soft disable-cursor mb-2">{rentals.length} of 3 rentals connected</p>
                  }
                  <Link to="/purchase" className="font-weight-bold small" onClick={this.handleOnClose}>Upgrade</Link>
                </div>
              }
              {subscription.productName === 'basic' &&
                <div className="p-1">
                  <p className="h6 font-weight-bold text-white disable-cursor mb-0">Basic Plan</p>
                  {connectedCount > 0 &&
                    <p className="small text-soft disable-cursor mb-2">{connectedCount} of 10 accounts connected</p>
                  }
                  {rentals.length > 0 &&
                    <p className="small text-soft disable-cursor mb-2">{rentals.length} of 15 rentals connected</p>
                  }
                  <Link to="/purchase" className="font-weight-bold small" onClick={this.handleOnClose}>Upgrade</Link>
                </div>
              }
              {subscription.productName === 'premium' &&
                <div className="p-1">
                  <p className="h6 font-weight-bold text-white disable-cursor mb-0">Premium Plan</p>
                  {connectedCount > 0 &&
                    <p className="small text-soft disable-cursor mb-2">{connectedCount} accounts connected</p>
                  }
                  {rentals.length > 0 &&
                    <p className="small text-soft disable-cursor mb-2">{rentals.length} rentals connected</p>
                  }
                </div>
              }

            </BS.Card>
          </BS.Row>
          <span className="text-muted small disable-cursor">Wealth</span>
          <Link id="dashboard" className="menu-item text" to="/dashboard" onClick={this.handleOnClose}><FontAwesomeIcon icon={FA.faLayerGroup} className="mr-2 text-muted small" />Dashboard</Link>
          <Link id="net-worth" className="menu-item" to="/net-worth" onClick={this.handleOnClose}><FontAwesomeIcon icon={FA.faChartLine} className="mr-2 text-muted small" />Networth</Link>
          <Link id="portfolio" className="menu-item" to="/portfolio" onClick={this.handleOnClose}><FontAwesomeIcon icon={FA.faBriefcase} className="mr-2 text-muted small" />Portfolio</Link>
          <Link id="rentals" className="menu-item" to="/rentals" onClick={this.handleOnClose}><FontAwesomeIcon icon={FA.faHome} className="mr-2 small text-muted" />Rentals</Link>

          <span className="text-muted small disable-cursor mt-5">Resources</span>
          {/* <Link id="tools" className="menu-item text" to="/tools" onClick={this.handleOnClose}><FontAwesomeIcon icon={FA.faCalculator} className="mr-2 text-muted small" />Tools</Link> */}
          {/* <Link id="learn" className="menu-item text" to="/learn" onClick={this.handleOnClose}><FontAwesomeIcon icon={FA.faBook} className="mr-2 text-muted small" />Learn</Link> */}
          <Link id="contact" className="menu-item text pb-5" to="/contact-us"><FontAwesomeIcon icon={FA.faEnvelope} className="mr-2 text-muted small" />Contact</Link>

        </BurgerMenu>

        <BS.Navbar bg="white" expand="lg" fixed="top">
          <div className="logo-align">
            <Link id="dashboard" className="menu-item text" to="/dashboard" onClick={this.handleOnClose}>
              <BS.Navbar.Brand className="pt-0">
                <img style={isMenuOpen ? { transform: 'translate3d(320px, 0px, 0px)', transition: 'all 0.5s ease 0s' } : { transition: 'all 0.5s ease 0s' }} className={isMenuOpen ? "translate3d(320px, 0px, 0px);" : ""} src={logo} alt="Investaflow logo" height="18" />
              </BS.Navbar.Brand>
            </Link>
          </div>
        </BS.Navbar>
      </>

    )
  }
}

export default withRouter(Menu)