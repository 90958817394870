import React, { Suspense, lazy } from 'react'
import { BrowserRouter as Router, HashRouter, Switch } from "react-router-dom"
import { ToastContainer } from 'react-toastify'
import LogRocket from 'logrocket'

import 'react-toastify/dist/ReactToastify.css'
import './custom.scss'

import ScrollToTop from './components/general/ScrollToTop'

import InternalRouteWrapper from './components/shared/InternalRouteWrapper'
import ExternalRouteWrapper from './components/shared/ExternalRouteWrapper'
import SharedRouteWrapper from './components/shared/SharedRouteWrapper'

const Home = lazy(() => import('./pages/general/Home'))
const Privacy = lazy(() => import('./pages/general/Privacy'))
const Terms = lazy(() => import('./pages/general/Terms'))
const Contact = lazy(() => import('./pages/general/Contact'))
const Login = lazy(() => import('./pages/general/Login'))
const Profile = lazy(() => import('./pages/general/Profile'))
const Purchase = lazy(() => import('./pages/general/Purchase'))
const Dashboard = lazy(() => import('./pages/general/Dashboard'))

const Cashflow = lazy(() => import('./pages/wealth/Cashflow'))
const NetWorth = lazy(() => import('./pages/wealth/NetWorth'))
const AssetDetail = lazy(() => import('./pages/wealth/AssetDetail'))
const IncomeDetail = lazy(() => import('./pages/wealth/IncomeDetail'))
const DebtDetail = lazy(() => import('./pages/wealth/DebtDetail'))
const ExpenseDetail = lazy(() => import('./pages/wealth/ExpenseDetail'))

const LandingNetWorth = lazy(() => import('./pages/landing/LandingNetWorth'))
const LandingRentals = lazy(() => import('./pages/landing/LandingRentals'))

const Rentals = lazy(() => import('./pages/rental/Rentals'))
const RentalDetail = lazy(() => import('./pages/rental/RentalDetail'))
const PropertyDetail = lazy(() => import('./pages/rental/PropertyDetail'))
const PurchaseDetail = lazy(() => import('./pages/rental/PurchaseDetail'))

class App extends React.Component {
  constructor(props) {
    super(props)

    LogRocket.init('zs0mvl/financial-app')
  }

  render() {
    if (window.cordova) {
      return (
        <HashRouter>
          <ScrollToTop />
          <ToastContainer />
          <Suspense fallback={<></>}>
            <Switch>
              {/* authenticated pages */}
              <InternalRouteWrapper path="/dashboard" component={Dashboard} />
              <InternalRouteWrapper path="/portfolio" component={Cashflow} />
              <InternalRouteWrapper path="/net-worth" component={NetWorth} />
              <InternalRouteWrapper path="/profile" component={Profile} />
              <InternalRouteWrapper path="/asset/:assetId" component={AssetDetail} />
              <InternalRouteWrapper path="/income/:incomeId" component={IncomeDetail} />
              <InternalRouteWrapper path="/debt/:debtId" component={DebtDetail} />
              <InternalRouteWrapper path="/expense/:expenseId" component={ExpenseDetail} />
              <InternalRouteWrapper path="/rentals" component={Rentals} />
              <InternalRouteWrapper path="/rental/:rentalId/property-detail" component={PropertyDetail} />
              <InternalRouteWrapper path="/rental/:rentalId/purchase-detail" component={PurchaseDetail} />
              <InternalRouteWrapper path="/rental/:rentalId" component={RentalDetail} />

              {/* combo pages */}
              <SharedRouteWrapper path="/privacy-policy" component={Privacy} />
              <SharedRouteWrapper path="/terms-of-use" component={Terms} />
              <SharedRouteWrapper path="/contact-us" component={Contact} />
              <SharedRouteWrapper path="/purchase" component={Purchase} />

              {/* unauthenticated pages */}
              <ExternalRouteWrapper path="/signup" isLogin={false} component={Login} />
              <ExternalRouteWrapper path="/login" isLogin={true} component={Login} />
              <ExternalRouteWrapper path="/net-worth-tracker" component={LandingNetWorth} />
              <ExternalRouteWrapper path="/rental-properties" component={LandingRentals} />
              <ExternalRouteWrapper path="/" isLogin={false} component={Login} />

            </Switch>
          </Suspense>
        </HashRouter>
      )
    } else {
      return (
        <Router>
          <ScrollToTop />
          <ToastContainer />
          <Suspense fallback={<></>}>
            <Switch>
              {/* authenticated pages */}
              <InternalRouteWrapper path="/dashboard" component={Dashboard} />
              <InternalRouteWrapper path="/portfolio" component={Cashflow} />
              <InternalRouteWrapper path="/net-worth" component={NetWorth} />
              <InternalRouteWrapper path="/profile" component={Profile} />
              <InternalRouteWrapper path="/asset/:assetId" component={AssetDetail} />
              <InternalRouteWrapper path="/income/:incomeId" component={IncomeDetail} />
              <InternalRouteWrapper path="/debt/:debtId" component={DebtDetail} />
              <InternalRouteWrapper path="/expense/:expenseId" component={ExpenseDetail} />
              <InternalRouteWrapper path="/rentals" component={Rentals} />
              <InternalRouteWrapper path="/rental/:rentalId/property-detail" component={PropertyDetail} />
              <InternalRouteWrapper path="/rental/:rentalId/purchase-detail" component={PurchaseDetail} />
              <InternalRouteWrapper path="/rental/:rentalId" component={RentalDetail} />

              {/* combo pages */}
              <SharedRouteWrapper path="/privacy-policy" component={Privacy} />
              <SharedRouteWrapper path="/terms-of-use" component={Terms} />
              <SharedRouteWrapper path="/contact-us" component={Contact} />
              <SharedRouteWrapper path="/purchase" component={Purchase} />

              {/* unauthenticated pages */}
              <ExternalRouteWrapper path="/signup" isLogin={false} component={Login} />
              <ExternalRouteWrapper path="/login" isLogin={true} component={Login} />
              <ExternalRouteWrapper path="/net-worth-tracker" component={LandingNetWorth} />
              <ExternalRouteWrapper path="/rental-properties" component={LandingRentals} />
              <ExternalRouteWrapper path="/" component={Home} />

            </Switch>
          </Suspense>
        </Router>
      )
    }

  }

}

export default App
